<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-for="(view, index) in viewsInfo" :key="index">
      <!-- Resumen inicial -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <strong>
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div>
              <strong>GENERAL INFO</strong>
            </div>
            <div class="info-content">
              <div class="legend">
                <div class="titulo">{{$t('label.legend')}}</div>
                <div class="preferences">
                  <div class="leyenda">
                    <div class="legend-container">
                      <div
                        v-for="item in legendItems(view)"
                        :key="item.Name"
                        class="legend-wrapper"
                      >
                        <div class="legend-icon">
                          <div
                            class="bg-position"
                            :style="{ backgroundColor: colorPreference(item) }"
                            v-if="hasColor(item)"
                          ></div>

                          <div :class="item.Class || null" v-if="!isFromPreference(item)"></div>
                          <div :class="slugName(item.Name)" v-if="isFromPreference(item)"></div>

                          <!-- Imprimir Letra -->
                          <img
                              :src="getLetter(item)"
                              alt=""
                              class="center-icon"
                              v-if="hasLetter(item)"
                            >
                        </div>
                        <span
                          class="legend-text"
                          :class="{ 'ml-5': checkIsOverSizeRight(item) }"
                        >
                          {{ hasLetter(item) ? getLetterName(item) : item.Name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="resumen">
                <div class="titulo">{{$t('label.resume')}}</div>
                <div class="cantidad">
                  <table class="principal">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <table>
                            <thead>
                              <tr>
                                <th colspan="3">E</th>
                                <th colspan="3">F</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>20'</td>
                                <td>40'</td>
                                <td>45'</td>
                                <td>20'</td>
                                <td>40'</td>
                                <td>45'</td>
                              </tr>
                            </tbody>
                          </table>
                        </th>
                        <th class="totals-10">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="hasFilter(view)">
                        <td class="font-weight-bold">{{ filterName(view) }}</td>
                        <td class="px-0">
                          <table class="secundarias">
                            <tr>
                              <td>{{ get20Empty(index) }}</td>
                              <td>{{ get40Empty(index) }}</td>
                              <td>{{ get45Empty(index) }}</td>
                              <td>{{ get20Full(index) }}</td>
                              <td>{{ get40Full(index) }}</td>
                              <td>{{ get45Full(index) }}</td>
                            </tr>
                          </table>
                        </td>
                        <td>{{ getTotalContainers(index) }}</td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="font-weight-bold">TOTAL</td>
                        <td class="px-0">
                          <table class="secundarias">
                            <tr>
                              <td>{{ get20Empty(index) }}</td>
                              <td>{{ get40Empty(index) }}</td>
                              <td>{{ get45Empty(index) }}</td>
                              <td>{{ get20Full(index) }}</td>
                              <td>{{ get40Full(index) }}</td>
                              <td>{{ get45Full(index) }}</td>
                            </tr>
                          </table>
                        </td>
                        <td rowspan="2">{{ getTotalContainers(index) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <table class="secundarias totals">
                            <tr>
                              <td>{{ getTotalEmpty(index) }}</td>
                              <td>{{ getTotalFull(index) }}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="transversal">
                <div class="titulo">{{$t('label.transversalView')}}</div>
                <div class="vista">
                  <img :src="containerShipImg" alt="" />
                  <div class="top-half">
                    <transversal-row 
                      :items="transversalView.formatedToDeck"
                      class="vessel-numbers"
                      showCode
                      onlyNumbers
                      :tooltip="false"
                    />
                    <transversal-row 
                      :items="transversalView.formatedToDeck"
                      class="vessel-todeck"
                      :tooltip="false"
                    />
                  </div>
                  <div class="bottom-half">
                    <transversal-row 
                      :items="transversalView.formatedUnderDeck"
                      class="vessel-underdeck"
                      alignTop
                      :tooltip="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText(view) }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page") }}: {{ getCurrentPageNumber(index+1, index) }}
              {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>

      <!-- Matching -->
      <div v-if="view.matching != null">
        <section class="pdf" v-for="(table, j) in view.matching.tables" :key="j">
          <section class="header">
            <div class="logo-container">
              <img :src="hasLogo" alt="SIGET" class="big-logo" />
            </div>
            <div class="title-container">
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div class="logo-container">
              <img
                src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
                alt="SIGET"
                class="small-logo"
              />
            </div>
          </section>
  
          <section class="body">
            <MatchingTable
              v-if="view.matching != null"
              scale
              :colsTotales="view.matching.results"
              :results="table.results"
              :rows="table.labels"
              :cols="view.matching.col"
              :lastTable="index == (view.matching.tables.length - 1)"
              class="matching-container"
            />
          </section>
  
          <section class="footer">
            <div class="corners-container">
              <p>{{ currentDateTime }}</p>
              <p class="usuarios">{{ loginName }}</p>
            </div>
            <div class="text-container">
              <p>{{ $t("label.matching") }}</p>
            </div>
            <div class="corners-container">
              <p></p>
            </div>
          </section>
        </section>
      </div>

      <!-- Paginas normales -->
      <section class="pdf" v-for="(n, i) in numberOfPages" :key="i">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong>
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="plan center-bays">
            <CRow class="flex-one-bays">
              <CCol sm="12">
                <div class="zoom-container zoom-center">
                  <div class="bay-container" :style="{ width: `${zoomByBays}%` }">
                    <div
                      class="bay-row"
                      :style="{
                        width: `calc( calc(100% / ${columnas}) - 10px )`,
                      }"
                      v-for="(bays, index) in pageList(n)"
                      :key="index"
                    >
                      <div
                        class="bay-col"
                        v-for="(bay, index) in bays"
                        :key="index"
                      >
                        <bay-container
                          v-if="bay != null"
                          :tooltip="false"
                          :headerSize="50"
                          :margin="0.5"
                          obligatory
                          showStatusView
                          :matching="getFilterMatching(view)"
                          :bayInfo="bay"
                          :positionsToDeck="getPositionsToDeck(bay)"
                          :positionsUnderDeck="getPositionsUnderDeck(bay)"
                          :colSize="generalCol"
                          :filterPort="currentFilterByPort(view)"
                          :preferences="view.preferences"
                          :viewElement="view.Element ? view.Element : ''"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText(view) }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page") }}: {{ getCurrentPageNumber(n+(index+1), index) }}
              {{ $t("label.of") }} {{ totalPages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayMixin from "@/_mixins/bays";
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import TransversalRow from "@/pages/planificacion-estiba/archivos/pdf/transversal-row";
import TransversalView from '@/pages/buque/bays/transversal-view';
import { GenerateSvgPlaceholder, slugify } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';
import MatchingTable from "@/pages/planificacion-estiba/archivos/detalles/matching-table";
import TransversalSrc from '@/_mixins/transversalViewSrc';

//Data
function data() {
  return {
    isLoading: false,
    pageCount: 0,
    resumenes: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || 'es';
  for (let i = 0; i < this.viewsInfo.length; i++) {
    this.resumenes.push(this.containersQuantity(this.viewsInfo[i]));
  }
}

//Metodos:
function pageList(currentPage) {
  var begin = (currentPage - 1) * this.numberPerPage;
  var end = begin + this.numberPerPage;

  return this.baysList.slice(begin, end);
}
function currentLegends(legend, pref) {
  return [...legend, ...pref];
}
function hasItineraryInfo(info) {
  if (!this.itineraryInfo) return "";

  return info;
}
function currentFilterByPort(view) {
  if (Object.entries(view.filter).length === 0) {
    return {
      FilterType: "",
      PortId: "",
      PortCode: "",
    };
  }

  return {
    FilterType: view.filter.FilterType,
    PortId: view.filter.PortId,
    PortCode: view.filter.PortCode,
  };
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function getCurrentPageNumber(n, viewIndex) {
  return (n + this.numberOfPages * viewIndex);
}
function hasFilter(view) {
  return Object.keys(view.filter).length != 0;
}
function filterName(view) {
  return this.hasFilter(view) ? view.filter.PortCode.substr(2) : '';
}
function footerText(view) {
  let filtro = '';
  if(this.hasFilter(view))
    filtro = ` - ${this.$t("label.filteredBy")} ${view.filter.PortCode}`;

  return `LOAD PLAN: ${view.EdiViewName}${filtro}`;
}
function containersQuantity(view) {
  let count40F = 0, count20F = 0, count45F = 0,
      count40 = 0, count20 = 0, total = 0, count45 = 0;

  if(this.hasFilter(view)){
    for (let i = 0; i < this.unformatedList.length; i++) {
      for (let j = 0; j < this.unformatedList[i].PositionJson.length; j++) {
        if(this.unformatedList[i].PositionJson[j].CargoJson && this.unformatedList[i].PositionJson[j].CargoJson[0][view.filter.FilterType] ==  view.filter.PortId) {
          total++;

          if(this.unformatedList[i].PositionJson[j].CargoJson[0].Size == "45"){
            if(this.unformatedList[i].PositionJson[j].CargoJson[0].TpCargoStatusId == 'F26262C7-2080-4581-8E8F-F3723C66DB96')
              count45++;
            else
              count45F++;
          }
          if(this.unformatedList[i].PositionJson[j].CargoJson[0].Size == "40"){
             if(this.unformatedList[i].PositionJson[j].CargoJson[0].TpCargoStatusId == 'F26262C7-2080-4581-8E8F-F3723C66DB96')
              count40++;
            else
              count40F++;
          }
          if(this.unformatedList[i].PositionJson[j].CargoJson[0].Size == "20"){
             if(this.unformatedList[i].PositionJson[j].CargoJson[0].TpCargoStatusId == 'F26262C7-2080-4581-8E8F-F3723C66DB96')
              count20++;
            else
              count20F++;
          }
        } 
      }
    }
  } else {
    for (let i = 0; i < this.unformatedList.length; i++) {
      for (let j = 0; j < this.unformatedList[i].PositionJson.length; j++) {
        if(this.unformatedList[i].PositionJson[j].CargoJson) {
          total++;

          if(this.unformatedList[i].PositionJson[j].CargoJson[0].Size == "45"){
            if(this.unformatedList[i].PositionJson[j].CargoJson[0].TpCargoStatusId == 'F26262C7-2080-4581-8E8F-F3723C66DB96')
              count45++;
            else
              count45F++;
          }
          if(this.unformatedList[i].PositionJson[j].CargoJson[0].Size == "40") {
            if(this.unformatedList[i].PositionJson[j].CargoJson[0].TpCargoStatusId == 'F26262C7-2080-4581-8E8F-F3723C66DB96')
              count40++;
            else
              count40F++;
          }
          if(this.unformatedList[i].PositionJson[j].CargoJson[0].Size == "20"){
            if(this.unformatedList[i].PositionJson[j].CargoJson[0].TpCargoStatusId == 'F26262C7-2080-4581-8E8F-F3723C66DB96')
              count20++;
            else
              count20F++;
          }
        }
      }
    }
  }

  return {
    ft40: count40,
    ft20: count20,
    ft45: count45,
    ft40F: count40F,
    ft20F: count20F,
    ft45F: count45F,
    total: total,
  }
}

function get45Empty(index) {
  return this.resumenes.length != 0 ? this.resumenes[index].ft45 : 0;
}
function get40Empty(index) {
  return this.resumenes.length != 0 ? this.resumenes[index].ft40 : 0;
}
function get20Empty(index) {
  return this.resumenes.length != 0 ? this.resumenes[index].ft20 : 0;
}
function get45Full(index) {
  return this.resumenes.length != 0 ? this.resumenes[index].ft45F : 0;
}
function get40Full(index) {
  return this.resumenes.length != 0 ? this.resumenes[index].ft40F : 0;
}
function get20Full(index) {
  return this.resumenes.length != 0 ? this.resumenes[index].ft20F : 0;
}
function getTotalContainers(index) {
  return this.resumenes.length != 0
  ? this.resumenes[index].total
  : 0;
}
function getTotalEmpty(index) {
  return this.resumenes.length != 0
  ? this.resumenes[index].ft20 + this.resumenes[index].ft40 + this.resumenes[index].ft45
  : 0;
}
function getTotalFull(index) {
  return this.resumenes.length != 0
  ? this.resumenes[index].ft20F + this.resumenes[index].ft40F + this.resumenes[index].ft45F
  : 0;
}
function legendItems(view) {
  return [...view.legends, ...view.preferences];
}
function hasColor(item) {
  return item.Color && item.FgColor;
}
function isFromPreference(item) {
  return item.Class ? false : true;
}
function slugName(name) {
  return slugify(name);
}
function colorPreference(item) {
  return this.hasColor(item) ? item.Color : null;
}
function checkIsOverSizeRight(item) {
  return item.Class == "oversize-right" ? true : null;
}
function hasLetter(item) {
  return item.Class && item.Class == 'letter';
}
function getLetter(item) {
  if(!this.hasLetter(item))
    return '';

  let index = item.Name.indexOf("(");
  if(index == -1)
    return '';

  return GenerateSvgPlaceholder({
    width: 40,
    height: 40,
    bgColor: 'transparent',
    textColor: '#3c4b64',
    fontSize: 30,
    fontWeight: '400',
    text: `${item.Name.slice(index+1, index+2)}`
  });
}
function getLetterName(item) {
  if(!this.hasLetter(item))
    return '';

  let index = item.Name.indexOf("(");
  if(index == -1)
    return '';

  return item.Name.slice(0, index-1);
}
function getFilterMatching(view) {
  return !view.matching
    ? []
    : [...view.matching.row, ...view.matching.col]
}

//Computeds:
function numberOfPages() {
  return Math.ceil(this.baysList.length / this.numberPerPage);
}
function totalPages() {
  return (this.numberOfPages * this.viewsInfo.length) + this.viewsInfo.length;
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromItinerary() {
  if (!this.itineraryInfo) return "";

  if(this.modulo == 'stowage') {
    let vesselName = this.itineraryInfo.VesselName,
      imo = this.itineraryInfo.Imo,
      voyage = this.itineraryInfo.Voyage,
      eta = this.itineraryInfo.Eta,
      etd = this.itineraryInfo.Etd;
  
    return `${vesselName} ${imo} / V:${voyage} - ETA:${eta} ETD:${etd}`;
  }
  if(this.modulo == 'visit') {
    let vesselName = this.itineraryInfo.VesselName,
      imo = this.itineraryInfo.Imo,
      voyage = this.itineraryInfo.Voyage,
      labelArrival = this.itineraryInfo.LabelArrival,
      labelDeparture = this.itineraryInfo.LabelArrival,
      arrival = this.itineraryInfo.Arrival,
      departure = this.itineraryInfo.Departure;

    return `${vesselName} ${imo} / V:${voyage} - ${labelArrival}:${arrival} ${labelDeparture}:${departure}`;
  }

  return '';
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}`: '';
}
function zoomByBays() {
  let filas = this.numberPerPage / this.columnas;

  if(filas == 1) {
    switch (this.columnas) {
      case 1:
        return 30;
      case 2:
        return 55;
      case 3:
        return 75;
      case 4:
        return 95;
      case 5:
      case 6:
      case 7:
      case 8:
        return 98;
      default:
        return 100;
    }
  }
  
  if(filas == 2){
    switch (this.columnas) {
      case 1:
        return 12;
      case 2:
        return 24;
      case 3:
        return 34;
      case 4:
        return 44;
      case 5:
        return 54;
      case 6:
        return 64;
      case 7:
        return 74;
      default:
        return 98;
    }
  }
}

export default {
  name: "full-cargo-pdf",
  props: {
    baysList: {
      type: Array,
      default: () => [],
    },
    unformatedList: {
      type: Array,
      default: () => [],
    },
    viewsInfo: {
      type: Array,
      default: () => [],
    },
    transversalView: {
      type: Object,
      default: () => {
        return {
          formatedToDeck: [],
          formatedUnderDeck: [],
        }
      },
    },
    itineraryInfo: {
      type: Object,
      default: null,
    },
    columnas: {
      type: Number,
      default: 3,
    },
    generalCol: {
      type: Number,
      default: 12,
    },
    numberPerPage: {
      type: Number,
      default: 3,
    },
    modulo: {
      type: String,
      default: 'stowage'
    },
  },
  mixins: [BayMixin, TransversalSrc],
  components: {
    BayContainer,
    TransversalRow,
    TransversalView,
    MatchingTable,
  },
  data,
  created,
  methods: {
    pageList,
    hasItineraryInfo,
    currentLegends,
    currentFilterByPort,
    getCurrentPageNumber,
    legendItems,
    hasColor,
    isFromPreference,
    hasLetter,
    slugName,
    colorPreference,
    checkIsOverSizeRight,
    getLetter,
    getLetterName,
    footerText,
    hasFilter,
    containersQuantity,
    get40Empty,
    get20Empty,
    get45Empty,
    get40Full,
    get20Full,
    get45Full,
    getTotalContainers,
    getTotalEmpty,
    getTotalFull,
    filterName,
    getFilterMatching,
  },
  computed: {
    numberOfPages,
    totalPages,
    currentDateTime,
    titleFromItinerary,
    hasLogo,
    loginName,
    zoomByBays,
    ...mapState({
      dropItem: (state) => state.planificacionestiba.dropItem,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    dropItem: function (newItem) {
      if (newItem != 15) {
        this.pageCount = 0;
      }
    },
  },
};
</script>

<style scoped src="./pdf-styles.css"></style>
<style scoped>
.zoom-container {
  width: 100%;
  max-height: 670px;
  overflow: hidden;
}
.zoom-center {
  display: flex;
  justify-content: center;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.bay-row {
  position: relative;
  margin: 0 0 5px 5px;
  width: calc(calc(100% / 3) - 10px);
  padding: 10px 0;

  display: flex;
  flex-direction: column;
}

.bay-col {
  position: relative;
  width: 100%;
  flex: 1;
}
.bay-col:first-child {
  margin-bottom: 15px;
}

/* *************************************************** */
/* Resumen */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info strong {
  font-size: 1.3rem;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 600px;

  display: flex;
  flex-wrap: wrap;
  border: 1px solid #000;
  border-radius: 4px;
}
.pdf .body .general-info .info-content .legend,
.pdf .body .general-info .info-content .resumen {
  height: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
}
.info-content .legend {
  width: 65%;
}
.info-content .resumen {
  width: 35%;
}

.pdf .body .general-info .info-content .transversal {
  width: 100%;
  height: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
}

.info-content .legend .titulo,
.info-content .resumen .titulo {
  font-size: 1.3rem;
  font-weight: bold;
  align-self: flex-start;
  margin: 5px 0 0 20px;
}

.info-content .resumen .cantidad {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}
.info-content .resumen .cantidad .principal {
  border-collapse: collapse;
  height: 80%;
  width: 100%;
}
.cantidad .principal,
.cantidad .principal th,
.cantidad .principal td {
  border: 2px solid #000;
  text-align: center;
}
.cantidad .principal th {
  padding: 0;
}
.info-content .resumen .cantidad .principal table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .resumen .cantidad .principal table td {
  border: 2px solid black;
}
.info-content .resumen .cantidad .principal .secundarias td {
  width: calc(100% / 6);
  padding: 0;
}
.info-content .resumen .cantidad .principal .secundarias.totals td{
  width: calc(100% / 2);
  padding: 0;
}
.cantidad td,
.cantidad .totals-10 {
  text-align: center;
  padding: 0 10px;
}

.info-content .transversal .titulo {
  font-size: 1.3rem;
  font-weight: bold;
  align-self: flex-end;
  margin: 5px 20px 20px 0;
}
.info-content .transversal .vista {
  width: 750px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.info-content .transversal .vista img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.info-content .transversal .vista .top-half { /*IMPORTANT*/
  position:absolute;
  top: 0;
  bottom: 33%;
  /* left: 22%;
  right: 12%; */
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info-content .transversal .vista .bottom-half { /*IMPORTANT*/
  position:absolute;
  top: 69%;
  bottom: 4%;
  /* left: 22%;
  right: 12%; */
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
}

.info-content .legend .preferences {
  width: 95%;
  height: 100%;
  margin: 10px 0;
  border: 2px solid #000;
  border-radius: 5px;
}

/* *************************************************** */
/* Leyenda */
/* *************************************************** */
.legend-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
    margin-top: 10px;
    /* overflow: hidden; */
  }
  .legend-wrapper {
    margin: 5px;
    width: calc(calc(100% / 4) - 10px);
    display: flex;
    align-items: center;

    /* border: 1px solid #000; */
  }
  .legend-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid #000;
    position: relative;
  }
  .legend-text {
    margin-left: 10px;
    font-size: 0.6rem;

    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
  }

  .bg-position {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    transition: background-color .5s ease-in-out;
  }
  /* Iconos */
  .flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  
  .imdg {
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    border: 1px solid red;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .high-cube,
  .hc-high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .high-cube-45,
  .footer-45 {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #373737;
  }
  .reefer-high-cube,
  .rt-refrigerado-high-cube  {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
  }
  .reefer {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .oversize-top {
    width: 100%;
    height: 50%;
    border: 1px solid #555;
    background: #555;
    -moz-border-radius: 100px 100px 0 0;
    -webkit-border-radius: 100px 100px 0 0;
    border-radius: 100px 100px 0 0;
    position: absolute;
    top: -50%;
    z-index: 100;
  }
  .oversize-right {
    width: 50%;
    height: 100%;
    border: 1px solid #555;
    background: #555;
    border-radius: 0 100px 100px 0;
    position: absolute;
    right: -50%;
    z-index: 1;
  }
  .oversize-left {
    width: 50%;
    height: 100%;
    border: 1px solid #555;
    background: #555;
    border-radius: 100px 0 0 100px;
    position: absolute;
    left: -50%;
    z-index: 1;
  }
  .oversize-back {
    width: 100%; 
    height: 20%; 
    border: 1px solid #555;
    background-color: #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
  }
  .oversize-front {
    width: 100%; 
    height: 20%; 
    border: 1px solid #555;
    background-color: #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    top: 0;
  }
  
  .empty {
    background-image: url('/img/iconos/Empty.svg');
    position: absolute;
    width: 25%;
    height: 25%;
    top: 4%;
    left: 75%;
  }
  .hazard-available {
    background-image: url('/img/iconos/AvailableHazard.svg');
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .hazard-restriction {
    background-image: url('/img/iconos/HazardRestriction.svg');
    position: absolute;
    width: 35%;
    height: 35%;
    top: 0;
    right: 0;
  }
  .reefer-plug {
    background-image: url('/img/iconos/ReeferPlug.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-by-40,
  .occupied-by-45 {
    background-image: url('/img/iconos/OccupiedBy40.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-by-45 {
    background-image: url('/img/iconos/Plus45_1.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .occupied-oversize,
  .reserved {
    background-image: url('/img/iconos/OccupiedOversize.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .restow {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    top: 0;
  }
  
  .center-icon { /* Center a .svg */
    position: absolute;
    width: 45%;
    height: 45%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .invalid-position {
    background-image: url('/img/iconos/ban-solid.svg');
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    position: absolute;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    -moz-transform: translate(-50%, -50%) rotate(90deg);
    -ms-transform: translate(-50%, -50%) rotate(90deg);
    -o-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .plan.center-bays {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .plan .flex-one-bays {
    flex: 1;
  }

  /* Matching */
  .matching-container {
    display: flex;
    align-items: center;
  }
</style>