<template>
  <div class="pdf-doc">
    <div v-if="matching != null">
      <div class="pdf-view" v-for="(table, index) in matching.tables" :key="index">
        <!-- Matching -->
        <section class="pdf" v-if="matching.tables.length != 0">
          <section class="header">
            <div class="logo-container">
              <img :src="hasLogo" alt="SIGET" class="big-logo" />
            </div>
            <div class="title-container">
              <strong>
                {{ titleFromItinerary }}
              </strong>
            </div>
            <div class="logo-container">
              <img
                src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
                alt="SIGET"
                class="small-logo"
              />
            </div>
          </section>
  
          <section class="body">
            <MatchingTable
              v-if="matching != null"
              scale
              :colsTotales="matching.results"
              :results="table.results"
              :rows="table.labels"
              :cols="matching.col"
              :lastTable="index == (matching.tables.length - 1)"
              class="matching-container"
            />
          </section>
  
          <section class="footer">
            <div class="corners-container">
              <p>{{ currentDateTime }}</p>
              <p class="usuarios">{{ loginName }}</p>
            </div>
            <div class="text-container">
              <p>{{ $t("label.matching") }}</p>
            </div>
            <div class="corners-container">
              <p>
                {{ $t("label.page") }}:
                {{ index + 1}}
                {{ $t("label.of") }} {{ totalPages + hasMatching }}
              </p>
            </div>
          </section>
        </section>
      </div>
    </div>
    <div class="pdf-view" v-for="(bay, index) in baysList" :key="index">
      <!-- Paginas normales -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong>
              {{ titleFromItinerary }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="plan">
            <CRow class="max-height">
              <CCol sm="12">
                <div class="zoom-container zoom-center">
                  <div
                    class="bay-view-container"
                    :style="{ width: `60%` }"
                  >
                    <div class="bay-view">
                      <bay-container
                        bayView
                        showStatusView
                        :maxSize="50"
                        :bayInfo="bay"
                        :numberSize="30"
                        :positionsToDeck="getPositionsToDeck(bay)"
                        :positionsUnderDeck="getPositionsUnderDeck(bay)"
                        :colSize="generalCol"
                        :filterPort="filter"
                        :header="false"
                        :matching="getFilterMatching()"
                      />
                    </div>
                  </div>
                  <div class="bay-info">
                    <div class="position-relative">
                      <ul>
                        <li>TOTAL TEUS: {{ bay.Teus }}</li>
                        <li>TOTAL CONT 20 FT: {{ getBayCountsBySize(bay, 20) }}</li>
                        <li>TOTAL CONT 40 FT: {{ getBayCountsBySize(bay, 40) }}</li>
                        <li>TOTAL CONT 45 FT: {{ getBayCountsBySize(bay, 45) }}</li>
                        <li>BAY {{ bay.BayCode }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page") }}:
              {{ index + 1 + hasMatching }}
              {{ $t("label.of") }} {{ totalPages + hasMatching }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayMixin from "@/_mixins/bays";
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import MatchingTable from "@/pages/planificacion-estiba/archivos/detalles/matching-table";

//Data
function data() {
  return {
    isLoading: false,
    pageCount: 0,
  };
}

//Created:
function created() {
  setTimeout(() => {
    window.print();
  }, 4000)
}

//Metodos:
function hasItineraryInfo(info) {
  if (!this.itineraryInfo) return "";

  return info;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function getBayCountsBySize(bay, size) {
  let contTwenty = 0, contForty = 0, contFortyFive = 0;

  bay.PositionJson.forEach((pos) => {
    if(pos.CargoJson != null && pos.CargoJson.length != 0){
      switch (pos.CargoJson[0].Size) {
        case "20":
          contTwenty++;
          break;
        case "40":
          contForty++;
          break;
        case "45":
          contFortyFive++;
          break;
        default:
          break;
      }
    }
  });

  if(size == 20)
    return contTwenty;
  if(size == 40)
    return contForty;
  if(size == 45)
    return contFortyFive;

  return 0
}
function getFilterMatching() {
  return !this.matching
    ? []
    : [...this.matching.row, ...this.matching.col]
}

//Computeds:
function footerText() {
  let filtro = this.filter.PortCode ? ` - ${this.$t("label.filteredBy")} ${this.filter.PortCode}` : '';

  return `BAY VIEW${filtro}`;
}
function totalPages() {
  return this.baysList.length;
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromItinerary() {
  if (!this.itineraryInfo) return "";

  if(this.modulo == 'stowage') {
    let vesselName = this.itineraryInfo.VesselName,
      imo = this.itineraryInfo.Imo,
      voyage = this.itineraryInfo.Voyage,
      eta = this.itineraryInfo.Eta,
      etd = this.itineraryInfo.Etd;
  
    return `${vesselName} ${imo} / V:${voyage} - ETA:${eta} ETD:${etd}`;
  }
  if(this.modulo == 'visit') {
    let vesselName = this.itineraryInfo.VesselName,
      imo = this.itineraryInfo.Imo,
      voyage = this.itineraryInfo.Voyage,
      labelArrival = this.itineraryInfo.LabelArrival,
      labelDeparture = this.itineraryInfo.LabelArrival,
      arrival = this.itineraryInfo.Arrival,
      departure = this.itineraryInfo.Departure;

    return `${vesselName} ${imo} / V:${voyage} - ${labelArrival}:${arrival} ${labelDeparture}:${departure}`;
  }

  return '';
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}

function hasMatching() {
  if(this.matching) return this.matching.tables.length;
  else return 0;
}

export default {
  name: "bay-view-pdf",
  props: {
    baysList: {
      type: Array,
      default: () => [],
    },
    itineraryInfo: {
      type: Object,
      default: null,
    },
    filter: {
      type: Object,
      default: () => {
        return {
          FilterType: "",
          PortId: "",
          PortCode: "",
        };
      }
    },
    generalCol: {
      type: Number,
      default: 12,
    },
    modulo: {
      type: String,
      default: 'stowage'
    },
    matching: {
      type: Object,
      default: null,
    },
  },
  mixins: [BayMixin],
  components: {
    BayContainer,
    MatchingTable,
  },
  data,
  created,
  methods: {
    hasItineraryInfo,
    getBayCountsBySize,
    getFilterMatching,
  },
  computed: {
    footerText,
    totalPages,
    currentDateTime,
    titleFromItinerary,
    hasLogo,
    loginName,
    hasMatching,
    ...mapState({
      dropItem: (state) => state.planificacionestiba.dropItem,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    dropItem: function (newItem) {
      if (newItem != 15) {
        this.pageCount = 0;
      }
    },
  },
};
</script>

<style scoped src="./pdf-styles.css"></style>
<style scoped>
.zoom-container {
  width: 100%;
  height: 100%;
  max-height: 670px;
  overflow: hidden;
}
.zoom-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.max-height {
  height: 100%;
}

/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.bay-view {
  margin: 10px 5px;
  width: 100%;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.bay-info {
  position: absolute;
  right: 1.5%;
  bottom: 10%;
  width: 220px;
  height: 85px;
  margin-left: auto;
  font-size: 1.05rem;
  font-weight: bold;
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Matching */
  .matching-container {
    display: flex;
    align-items: center;
  }
</style>