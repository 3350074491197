<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <full-cargo-pdf
        v-if="getCurrentType === 'load-plan'"
        :baysList="pageInfo.baysList"
        :unformatedList="pageInfo.unformatedList"
        :viewsInfo="pageInfo.viewsInfo"
        :itineraryInfo="pageInfo.itineraryInfo"
        :columnas="pageInfo.columnas"
        :generalCol="pageInfo.generalCol"
        :numberPerPage="pageInfo.numberPerPage"
        :transversalView="getTransversalView"
        :modulo="pageInfo.modulo"
      />
      <bay-view-pdf
        v-if="getCurrentType === 'bay-view'"
        :baysList="pageInfo.baysList"
        :itineraryInfo="pageInfo.itineraryInfo"
        :generalCol="pageInfo.generalCol"
        :filter="pageInfo.filter"
        :modulo="pageInfo.modulo"
        :matching="pageInfo.matching"
      />
    </div>
  </transition>
</template>

<script>
import FullCargoPdf from "@/pages/planificacion-estiba/archivos/pdf/full-cargo-pdf";
import BayViewPdf from "@/pages/planificacion-estiba/archivos/pdf/bay-view-pdf";
import debounce from 'lodash/debounce';

import ldb from 'localdata';

//Data
function data() {
  return {
    pageInfo: {
      baysList: [],
      viewsInfo: [],
      itineraryInfo: null,
      columnas: 3,
      generalCol: 12,
      numberPerPage: 1,
      modulo: 'stowage',
    },
    docTitle: '',
    UnderDeckJson: [],
    ToDeckJson: [],
    baysJson: [],
  };
}

//Created
function created() {
  // let info = JSON.parse(localStorage.getItem('pageInfo'));
  let info = "",
      vm = this;
  ldb.get('pageInfo', function (value) {
    info = JSON.parse(value);
    ldb.delete('pageInfo');

    if(!info) {
      window.close();
      return;
    }

    vm.pageInfo = {...vm.pageInfo, ...info};

    if(vm.pageInfo.type === 'bay-view') {}
    if(vm.pageInfo.type === 'load-plan') {
      vm.getDataFromService(vm.pageInfo.itineraryInfo.VesselId);
    }

    // localStorage.removeItem('pageInfo');
    
    window.addEventListener("beforeprint", function(event) {
      // document.title = `FULL CARGO PLAN`;
    });
    window.addEventListener("afterprint", function(event) {
      close();
    });
  });
}

//Methods:
function getDataFromService(id) {

  this.$http.ejecutar('GET', 'VesselTransversalView-by-vesselA', { VesselId: id })
  .then(response => {
    this.baysJson = response.data.data || [];
    this.$nextTick(() => {
      window.print();
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
function generateTiersArray(tierFrom, tierTo, bayCode) {
  if(!bayCode) return [];

  let arr = [],
    end = Number.parseInt(tierFrom, 10),
    start = Number.parseInt(tierTo, 10);

  for (let index = start; index >= end; index--) {
    if (Math.abs(index % 2) === 0) {
      arr.push({
        BayCode: bayCode,
        Tier: index < 10 ? `0${index}` : `${index}`,
        Code: `${bayCode}-${index}`,
      });
    }
  }
  return arr;
}

//Computed:
function formatedToDeck() {
  return this.baysJson.map((bay) => {
      if(bay.BayCode && bay.FgBay) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: bay.PositionJson[0].ToDeckJson.length == 1 ? this.generateTiersArray(
            bay.PositionJson[0].ToDeckJson[0].TierToDeckFrom,
            bay.PositionJson[0].ToDeckJson[0].TierToDeckTo,
            bay.BayCode,
          ) : [],
        });
      } else if(!bay.BayCode && bay.FgCrane) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: [],
        });
      }
  });
}
function formatedUnderDeck() {
  return this.baysJson.map((bay) => {
      if(bay.BayCode && bay.FgBay) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          Tiers: bay.PositionJson[0].UnderDeckJson.length == 1 ? this.generateTiersArray(
            bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckFrom,
            bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckTo,
            bay.BayCode,
          ) : [],
        });
      } else if(!bay.BayCode && bay.FgCrane) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          Tiers: [],
        });
      }
  });
}

function getTransversalView() {
  return {
    formatedToDeck: this.formatedToDeck,
    formatedUnderDeck: this.formatedUnderDeck,
  }
}
function getCurrentType() {
  return this.pageInfo ? this.pageInfo.type : '';
}

export default {
  name: "Reporte-PDF",
  components: {
    FullCargoPdf,
    BayViewPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      // window.print();
    })
  }, 250),
  methods: {
    getDataFromService,
    generateTiersArray,
  },
  computed: {
    formatedToDeck,
    formatedUnderDeck,
    getTransversalView,
    getCurrentType,
  },
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>